.docs {
  i:not([class~="pi"]) {
    background-color: transparent;
    color: #2196f3;
    font-family: Monaco, courier, monospace;
    font-style: normal;
    font-size: 12px;
    font-weight: 500;
    padding: 0 4px;
    letter-spacing: .5px;
    font-weight: 600;
    margin: 0 2px;
    display: inline-flex;
  }

  a {
    font-weight: 500;
  }

  pre[class*="language-"] {
    &:before, &:after {
      display: none !important;
    }

    code {
      border-left: 6px solid var(--surface-border) !important;
      box-shadow: none !important;
      background: var(--surface-ground) !important;
      margin: 1em 0;
      color: var(--text-color);
      font-size: 14px;
    }

    .token {
      &.tag,
      &.keyword {
        color: #2196F3 !important;
      }

      &.attr-name,
      &.attr-string {
        color: #2196F3 !important;
      }

      &.attr-value {
        color: #4CAF50 !important;
      }

      &.punctuation {
        color: var(--text-color);
      }

      &.operator,
      &.variable,
      &.string {
        background: transparent;
      }
    }
  }
}
